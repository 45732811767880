@import url("https://use.typekit.net/vep0jlq.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: lato, sans-serif;
    font-size: 0.9rem;
  }
}

button {
  outline: none;
}
